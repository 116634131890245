<template>
  <v-form @submit.prevent="submitResponse">
    <div>
      <p>
        For the reaction <stemble-latex class="no-text-transform" content="$\ce{X + Y -> Z}$" /> ,
        the following data was acquired:
      </p>
      <p>Experiment 1: [Y]<sub>o</sub>= 0.200 M</p>
      <br />
      <v-simple-table class="mb-5">
        <tbody>
          <tr class="mb-5">
            <td
              v-for="(option, index) in t1r1"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(option, index) in t1r2"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <p>Experiment 2: [Y]<sub>o</sub>= 0.400 M</p>
      <v-simple-table>
        <thead></thead>
        <tbody>
          <tr class="mb-5">
            <td
              v-for="(option, index) in t2r1"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(option, index) in t2r2"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="mt-5">
      <label for="answerPartA">
        a) What is k<sub>obs</sub> when [Y]<sub>o</sub> = 0.200 M? (You will need to graph the data
        to find this value.)
      </label>
      <calculation-input
        v-model="inputs.answerPartA"
        prepend-text="$k_\text{obs}:$"
        :disabled="!allowEditing"
        class="mb-5"
      />
    </div>
    <div>
      <label for="answerPartB"> b) What is k<sub>obs</sub> when [Y]<sub>o</sub> = 0.400 M? </label>
      <calculation-input
        v-model="inputs.answerPartB"
        prepend-text="$k_\text{obs}:$"
        :disabled="!allowEditing"
        class="mb-5"
      />
      <label for="answerPartC">
        c) Use the answers from parts (a) and (b) to find the reaction order in Y.
      </label>
      <calculation-input
        v-model="inputs.answerPartC"
        prepend-text="$\text{Order in Y:}$"
        :disabled="!allowEditing"
        class="mb-5"
      />
      <label for="answerPartD"> d) What is the rate law (rate equation) for this reaction? </label>
      <v-textarea
        v-model="inputs.answerPartD"
        label="rate law"
        outlined
        rows="1"
        row-height="2"
        no-resize
      />
      <label for="answerPartE"> e) Calculate the rate constant (k) for this reaction. </label>
      <calculation-input
        id="answerPartE"
        v-model="inputs.answerPartE"
        prepend-text="$k:$"
        :disabled="!allowEditing"
      />
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput';
import {submitResponse} from '@/tasks/api/task-responses';

export default {
  name: 'OttawaGotoHW7Q10',
  methods: {submitResponse},
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answerPartA: null,
        answerPartB: null,
        answerPartC: null,
        answerPartD: null,
        answerPartE: null,
      },
      t1r1: ['t (min)', '0', '1', '2', '3', '4', '5'],
      t1r2: ['[X] (mM)', '3.20', '1.68', '0.881', '0.462', '0.243', '0.127'],
      t2r1: ['t (min)', '0', '0.2', '0.4', '0.8', '1.8', '2.3'],
      t2r2: ['[X] (mM)]', '3.20', '1.91', '1.14', '0.406', '0.0516', '0.00610'],
    };
  },
};
</script>
